export const CONNECTION_CONNECTED = 'CONNECTION_CONNECTED';
export const CONNECTION_DISCONNECTED = 'CONNECTION_DISCONNECTED';

export const ERROR = 'ERROR';

export const CONFIGURE = 'CONFIGURE';
export const CONFIGURE_RETURNED = 'CONFIGURE_RETURNED';

export const STAKE = 'STAKE';
export const STAKE_RETURNED = 'STAKE_RETURNED';

export const GET_REWARDS = 'GET_REWARDS';
export const GET_REWARDS_RETURNED = 'GET_REWARDS_RETURNED';

export const EXIT = 'EXIT';
export const EXIT_RETURNED = 'EXIT_RETURNED';

export const WITHDRAW = 'WITHDRAW';
export const WITHDRAW_RETURNED = 'WITHDRAW_RETURNED';

export const GET_BALANCES = 'GET_BALANCES';
export const GET_BALANCES_RETURNED = 'GET_BALANCES_RETURNED';

export const GET_BALANCES_PERPETUAL = 'GET_BALANCES_PERPETUAL';
export const GET_BALANCES_PERPETUAL_RETURNED = 'GET_BALANCES_PERPETUAL_RETURNED';
